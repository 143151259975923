import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { setTitle } from "../../../common/js/tools";
import { setNavColor, setNavExpansion } from "../../../common/js/navigateTools";
import RepaymentOfCard from "../../../components/card/repaymentOfCard";
import Button from "../../../components/button";
import SafeArea from "../../../components/safeArea";
import "./repaymentOfCard.less";

const RepaymentOfCardPage = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `roundspoiling=${params.roundspoiling}&shookher=${params.shookher}&tarpaulin=${params.tarpaulin}&comfortablecar=${params.comfortablecar}&matterwhether=${params.matterwhether}&yourboat=${params.yourboat}&scratching=${params.scratching}&hereto=${params.hereto}&notch=${params.notch}`;
    useEffect(() => {
        setTitle("Repayment Method");
        setNavExpansion(0); // 是否隐藏头部导航栏
        setNavColor("#0F4743", "#FFFFFF");
    }, [])

    const cardRef = useRef();
    let repayConfirm = () => {
        cardRef.current.repayCommit();
    }

    return (
        <div className="P-repaymentOfCard">
            {/* <SafeArea /> */}
            <div className="cardArea">
                <RepaymentOfCard 
                ref={cardRef}
                amount={params.amount || ""}
                isDelay={params.isDelay} />
            </div>
            <div className="P-repaymentOfCard_btn">
                <Button
                content="Confirm"
                clickFunc={() => repayConfirm()} />
            </div>
        </div>
    )
}

export default RepaymentOfCardPage;