import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import Modal from "../modal";
import { loanPlayDetailService } from "../../axios/service";
import Button from "../button";
import "./index.less";

const RepaymentOfPeriodCom = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `roundspoiling=${params.roundspoiling}&shookher=${params.shookher}&tarpaulin=${params.tarpaulin}&comfortablecar=${params.comfortablecar}&matterwhether=${params.matterwhether}&yourboat=${params.yourboat}&scratching=${params.scratching}&hereto=${params.hereto}&notch=${params.notch}`;

    const [productName, setProductName] = useState("");
    const [repayPlanDetail, setRepayPlanDetail] = useState({}); // 内涵details
    const [totalAmount, setTotalAmount] = useState(""); // 总金额
    const [amount, setAmount] = useState(null); // 带货币符号的总金额
    const [note, setNote] = useState("");

    useEffect(() => {
        const getPeriod = async () => {
            let data = await loanPlayDetailService();
            setProductName(data.putthe || "");
            setRepayPlanDetail(data.foronce || {});
            setTotalAmount(data.passer || "");
            setAmount(data.frowning || "");
            setNote(data.recognise || "");
        }
        getPeriod();
    }, [])

    // 跳转还款方式页面
    const goReay = () => {
        props.closeFunc();
        let ip_amount = repayPlanDetail && repayPlanDetail.behaving && repayPlanDetail.behaving[0].passer || ""; // 还款接口需要的金额
        window.location.href = `/haseRealt?amount=${ip_amount}&isDelay=${0}&suggest=${params.suggest}&racing=${params.racing}&${paramsDetail}`;
    }
    return (
        <Modal titleContent="Select bill" close={() => props.closeFunc()}>
            <div className="M-period">
                {repayPlanDetail && repayPlanDetail.behaving && repayPlanDetail.behaving.length > 0 ? (
                    <ul className="M-period_list">
                        {repayPlanDetail.behaving.map((item, index) => {
                            return (
                                <li key={"pePlanI" + index}>
                                    <div className="list">
                                        <div className="list_1">
                                            <p>{item.bought}</p>
                                            <p>{item.smelling}</p>
                                        </div>
                                        <div className="list_2">
                                            <p>{item.scent}</p>
                                            <p>{item.biscuits}</p>
                                        </div>
                                    </div>
                                    {index == 0 ? (
                                        <p className="first_tips">{note || ""}</p>
                                    ) : null}
                                </li>
                            )
                        })}
                    </ul>
                ) : null}
                <div className="M-period_button">
                    <Button
                    content="Repay"
                    clickFunc={() => goReay()} />
                </div>
            </div>
        </Modal>
    )
}

export default RepaymentOfPeriodCom;