import http from "./axios";
import apiUrl from "./api";
// import qs from 'qs';
import { Qs, uuid } from "../common/js/tools";
const params = Qs(); // 获取params
// 确认用款页详情
export const loanDetailConfirmService = () => {
    return http.post(apiUrl.loanDetailConfirm, {
        racing: params.racing, // 产品id
    }, {
        racing: params.racing, // 产品id
        amournful: uuid(), // 混淆字段
    });
}
// 确认用款
export const loanDetailPushService = (data) => {
    let { amount, periods, termUnit } = data;
    return http.post(apiUrl.loanDetailPush, {
        comfortablecar: params.comfortablecar, // App版本
    }, {
        frowning: amount, // 金额
        toldthe: periods, // 期限
        kikiwent: termUnit, // 期限类型
        racing: params.racing, // 产品id
        discussed: uuid(), // 混淆字段
    });
}
// 订单详情页
export const loanDetailsService = () => {
    return http.get(apiUrl.loanDetails, {
        suggest: params.suggest, // 订单id
        racing: params.racing, // 产品id
        tarpaulin: params.tarpaulin, // 终端版本
        comfortablecar: params.comfortablecar, // App版本
        matterwhether: params.matterwhether || "melsyl", // 市场 写死melsyl
        yourboat: params.yourboat, // 取idfv
    }, {
        racing: params.racing, // 产品id
        suggest: params.suggest, // 订单id
        millionaire: uuid(), // 混淆字段
    });
}
// google评分
export const googleScoreService = (data) => {
    let { clickIndex } = data
    return http.post(apiUrl.googleScore, {}, {
        theshop: clickIndex, // 分数 1-5
        gobbled: uuid(), // 混淆字段
    });
}
// 原卡重试确认订单
export const userReloanService = (data) => {
    let { orderNo } = data;
    return http.post(apiUrl.userReloan, {}, {
        contain: orderNo, // 订单号
    });
}
// 获取还款计划信息
export const loanPlayDetailService = () => {
    return http.post(apiUrl.loanPlayDetail, {}, {
        suggest: params.suggest, // 订单id
        racing: params.racing, // 产品id
    });
}
// 获取还款方式
export const getLoanPaymentService = () => {
    return http.post(apiUrl.getLoanPayment, {
        suggest: params.suggest, // 订单id
        racing: params.racing, // 产品id
        amount: params.amount, // 传参金额
    }, {
        suggest: params.suggest, // 订单id
        racing: params.racing, // 产品id
    });
}
// 展期详情页
export const delayDetailService = () => {
    return http.get(apiUrl.delayDetail, {
        suggest: params.suggest, // 订单id
        racing: params.racing, // 产品id
    }, {
        suggest: params.suggest, // 订单id
        racing: params.racing, // 产品id
    });
}
// 获取绑卡信息（第五项）
export const cardInitService = () => {
    return http.get(apiUrl.cardInit, {
        fairlybig: 1, // 是否添加新卡（app认证项给0，h5添加新卡给1）
    }, {
        fairlybig: 1, // 是否添加新卡（app认证项给0，h5添加新卡给1）
        looming: uuid(), // 混淆字段
    });
}
// 提交绑卡（第五项）
export const bindCardNewService = (data) => {
    return http.post(apiUrl.bindCardNew, {}, data);
}
// 用户银行卡列表
export const userCardListService = () => {
    return http.post(apiUrl.userCardList, {}, {});
}
// 更换银行卡（会触发重新打款）
export const changeOrderAccountService = (data) => {
    let { bindId } = data;
    return http.post(apiUrl.changeOrderAccount, {}, {
        finger: bindId || "", // 绑卡id
        jacksuddenly: params.suggest || "", // 订单号 （订单列表、订单详情等页面需要给，个人中心这种拿不到的不用给）
    });
}
// 获取客服页面数据
export const contactKfInfoService = () => {
    return http.post(apiUrl.contactKfInfo, {}, {
        stuffed: uuid(), // 混淆字段
    });
}
// 获取还款code码
export const generateVcodeService = (from, data) => {
    return http.post(apiUrl.generateVcode, {
        suggest: params.suggest, // 订单id
        shape: params.racing, // 产品id
    }, {
        suggest: params.suggest, // 订单id
        shape: params.racing, // 产品id
        frowning: from == 'card' ?  data.amount : params.amount, // 金额
        restaurant: from == 'card' ? data.isDelay : params.isDelay, // 是否展期 1是 0否 暂无展期默认先写0吧
        extremely: from == 'card' ? parseInt(data.cardType) : parseInt(params.cardType), // 还款方式 1 钱包 2 银行 3 线下门店
        torches: from == 'card' ? data.typeCode : params.typeCode, // 申请code代码
        sells: uuid(), // 混淆字段
    });
}
// 取消自动确认用款
export const autoConfirmButtonService = (data) => {
    const { orderNo } = data;
    return http.post(apiUrl.autoConfirmButton, {}, {
        ofcraggy: -1, // 类型，取消传 -1
        jacksuddenly: orderNo, // 订单order_no
        millionaire: uuid(), // 混淆字段
    })
}
// 禁止产品详情 - 小黑屋
/*export const prohibitedProductService = () => {
    return http.post(apiUrl.prohibitedProduct, {
        suggest: params.suggest, // 订单id
        racing: params.racing, // 产品id
    }, {
        beats: params.racing, // 产品id
        impudent: uuid(), // 混淆字段
        cousinship: uuid(), // 混淆字段
    })
}*/