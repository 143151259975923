import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import qs from 'qs';
import { ToastType } from "../../common/js/toastList";
import { getLoanPaymentService, generateVcodeService } from '../../axios/service';
import "./repaymentOfCard.less";
import card_checked from "../../common/images/repay/card_checked.png";
import card_notChecked from "../../common/images/repay/card_notChecked.png";
import noneImg from "../../common/images/repay/noneImg.png";

const RepaymentOfCard = (props, ref) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `roundspoiling=${params.roundspoiling}&shookher=${params.shookher}&tarpaulin=${params.tarpaulin}&comfortablecar=${params.comfortablecar}&matterwhether=${params.matterwhether}&yourboat=${params.yourboat}&scratching=${params.scratching}&hereto=${params.hereto}&notch=${params.notch}`;

    const [ewallet, setEwallet] = useState([]);
    const [bank, setBank] = useState([]);
    const [overcounter, setOvercounter] = useState([]);
    const [choosedCardList, setChoosedCardList] = useState([]); // 选中的还款方式列表
    const [choosedCardType, setChoosedCardType] = useState(1); // 选中的还款方式类型
    const [checkedCardIndex, setCheckedCardIndex] = useState(null); // 选中的还款方式id
    const [amount, setAmount] = useState(null);

    const [amountTxt, setAmountTxt] = useState(""); // 展示的金额

    const [showAllList, setShowAllList] = useState(false); // 是否展示全部数组
    const [show_list, setShowList] = useState([]); // 有view-all 展示的数组

    const cardTypeList = [{
        typeIndex: 1,
        typeName: "E-WALLET",
     }, {
        typeIndex: 2,
        typeName: "BANK",
    }, {
        typeIndex: 3,
        typeName: "OVER-COUNTER",
    }];

    useEffect(() => {
        const fetchLoanPaymentInfo = async () => {
            let data = await getLoanPaymentService();
            let checked_index = data.overcome && data.overcome.length > 0 ? data.overcome[0].ofcraggy : null; // 默认ewallet的第一个
            setEwallet(data.overcome || []);
            setBank(data.tellmiss || []);
            setOvercounter(data.onhis || []);
            setChoosedCardList(data.overcome || []);
            setCheckedCardIndex(checked_index || "");
            // setAmount();

            setAmountTxt(data.noseinto || "");
        }
        fetchLoanPaymentInfo();
    }, [])
    // 选择卡类型
    const chooseCardType = (type) => {
        setChoosedCardType(type);
        let chosed_list = type == 1 ? ewallet : (type == 2 ? bank : overcounter);
        setChoosedCardList(chosed_list);
        let checked_index = chosed_list.length > 0 ? chosed_list[0].ofcraggy : null;
        setCheckedCardIndex(checked_index);
    }
    // 选择卡
    const chooseCardDetailType = (item) => {
        setCheckedCardIndex(item.ofcraggy);
    }
    // 跳转还款页/第三方 repay提交
    const repayCommit = () => {
        if(checkedCardIndex == undefined || checkedCardIndex == "" || checkedCardIndex == null) {
            ToastType('Toast', 'Please select one');
            return;
        }
        if(choosedCardList.length === 0) {
            ToastType('Toast', "You haven't added any cards yet");
            return;
        }
        jumpToCodePage(); // 跳转到详情条码页
        // console.log(this.state.choosedCardType, this.state.checkedCardIndex);
    }
    // 跳转到code页面
    const jumpToCodePage = async () => {
        let data = await generateVcodeService('card', {
            cardType: choosedCardType,
            typeCode: checkedCardIndex,
            amount: params.amount,
            isDelay: params.isDelay
        });
        // console.log(data);
        if(data.besidesbird != undefined && data.besidesbird.length > 0) {
            // window.location.replace(data.besidesbird); // 替换当前详情页跳转第三方
            window.location.href = data.besidesbird; // 跳转第三方
            return;
        } else {
            // 跳转code页面
            // navigate(`/wasntStood?cardType=${choosedCardType}&typeCode=${checkedCardIndex}&suggest=${params.suggest}&racing=${params.racing}&isDelay=${props.isDelay}&amount=${props.amount}&${paramsDetail}`);
            window.location.href = `/wasntStood?cardType=${choosedCardType}&typeCode=${checkedCardIndex}&suggest=${params.suggest}&racing=${params.racing}&isDelay=${params.isDelay}&amount=${params.amount}&${paramsDetail}`;
        }
        
    }
    useImperativeHandle(ref, () => ({
        repayCommit
    }))

    // 展示全部
    const showAll = () => {
        setShowAllList(true);
        // setShowList(choosedCardList);
    }
    return (
        <div className="M-card">
            {/* 金额显示 */}
            <div className="M-card_amount">
                <p className="title">Repayment Amount</p>
                <p className="amount">{amountTxt || "₱ 0"}</p>
            </div>
            {/* 选择卡区域 */}
            <div className="M-card_choseArea">
                {/* 卡类型选择 */}
                <ul className="M-card_typeList">
                    {cardTypeList.map((item, index) => {
                        return (
                            <li className={choosedCardType == item.typeIndex ? "choosedCardType" : "cardType"} key={"cardTi" + index} onClick={() => chooseCardType(item.typeIndex)}>
                                <p>{item.typeName}</p>
                            </li>
                        )
                    })}
                </ul>
                {/* 选择卡 */}
                {choosedCardList && choosedCardList.length > 0 ? (
                    <ul className="M-card_list">
                        {choosedCardList.map((item, index) => {
                            return (
                                <li key={"cardI" + index} onClick={() => chooseCardDetailType(item)}>
                                    <div className="list_info">
                                        {item.isleof && item.isleof.length > 0 ? <img src={item.isleof} /> : null}
                                        <div className={checkedCardIndex == item.ofcraggy ? "checkedItem" : ""}>
                                            <p>{item.whosegreen || ""}</p>
                                        </div>
                                    </div>
                                    {checkedCardIndex == item.ofcraggy ? <img src={card_checked} className="checkedImg" /> : <img src={card_notChecked} className="not_checkedImg" />}
                                </li>
                            )
                        })}
                    </ul>
                ) : (
                    <div className="M-card_none">
                        <img src={noneImg} className="noneImg" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default forwardRef(RepaymentOfCard);