export default {
    loanDetailConfirm: "/meiliy/childrenProbably", // 确认用款页详情
    loanDetailPush: "/meiliy/firstAuntpolly", // 确认用款
    loanDetails: "/meiliy/rememberingLonger", // 订单详情页
    googleScore: "/meiliy/wellAgreed", // google评分
    userReloan: "/meiliy/pollyOther", // 原卡重试确认订单
    loanPlayDetail: "/meiliy/whileQuarrels", // 获取还款计划信息
    getLoanPayment: "/meiliy/kitchenPiece", // 获取还款方式
    delayDetail: "/meiliy/sternlyWhole", // 展期详情页
    cardInit: "/meiliy/tappedDesolateisland", // 获取绑卡信息（第五项）
    bindCardNew: "/meiliy/wetoldComing", // 提交绑卡（第五项）
    userCardList: "/meiliy/aPendding", // 用户银行卡列表
    changeOrderAccount: "/meiliy/tocraggytopsLaugh", // 更换银行卡（会触发重新打款）
    contactKfInfo: "/meiliy/greatTheenormous", // 获取客服页面数据
    generateVcode: "/meiliy/ashelteredClosed", // 获取还款code码
    autoConfirmButton: "/meiliy/WeAreBlue", // 取消自动确认用款
    // prohibitedProduct: "/uiengllli", // 禁止产品详情 - 小黑屋
}