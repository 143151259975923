import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion, setNavColor } from "../../../common/js/navigateTools";
import { delayDetailService } from '../../../axios/service';
import RepaymentOfCard from "../../../components/card/repaymentOfCard";
import Button from "../../../components/button";
import SafeArea from "../../../components/safeArea";
import "./repayment.less";

const RepaymentOfDelay = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `roundspoiling=${params.roundspoiling}&shookher=${params.shookher}&tarpaulin=${params.tarpaulin}&comfortablecar=${params.comfortablecar}&matterwhether=${params.matterwhether}&yourboat=${params.yourboat}&scratching=${params.scratching}&hereto=${params.hereto}&notch=${params.notch}`;

    const [productLogo, setProductLogo] = useState("");
    const [productName, setProductName] = useState("");
    const [delayShouldPayAmount, setDelayShouldPayAmount] = useState(""); // 还款金额
    const [orderDetail, setOrderDetail] = useState([]); // 还款信息2
    const [amount, setAmount] = useState(""); // 借款金额

    const [loan_mode, setLoanMode] = useState([]); // 还款信息1

    useEffect(() => {
        setTitle("Extension");
        setNavExpansion(0); // 是否隐藏导航栏
        setNavColor("#FFFFFF", "#09C8AF");

        const getPeriod = async () => {
            let data = await delayDetailService();
            setProductLogo(data.grumpily || "");
            setProductName(data.putthe || "");
            setDelayShouldPayAmount(data.policeman || "");
            setOrderDetail(data.policemanangrily && data.policemanangrily.hearthey || []);
            setAmount(data.frowning || "");

            setLoanMode(data.policemanangrily && data.policemanangrily.theboat || []);
        }
        getPeriod();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // const cardRef = useRef();
    // let repayConfirm = () => {
    //     cardRef.current.repayCommit();
    // }
    const repayConfirm = () => {
        window.location.href = `/haseRealt?amount=${delayShouldPayAmount}&isDelay=${1}&suggest=${params.suggest}&racing=${params.racing}&${paramsDetail}`;
    }
    return (
        <div className="P-repayment">
            {/* <SafeArea /> */}
            {/* 金额和状态描述区域 */}
            <div className="P-repayment_amount">
                {loan_mode && loan_mode.length > 0 ? (
                    <ul className="delayInfo">
                    {loan_mode.map((item, index) => {
                        return (
                            <li key={"daleyI1" + index}>
                                <p className="info_left">{item.hatred || ""}</p>
                                <div className="info_right r_logo">
                                    {item.isleof ? <img src={item.isleof} /> : null}
                                    <p>{item.bound || ""}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                ) : null}
            </div>
            {/* 展期信息区域 */}
            <div className="P-repayment_delayInfo">
                {orderDetail && orderDetail.length > 0 && (
                    <ul className="delayInfo detailsInfo">
                        {orderDetail.map((item, index) => {
                            return (
                                <li key={"delayI" + index}>
                                    <p className="info_left">{item.hatred || ""}</p>
                                    <p className="info_right">{item.bound || ""}</p>
                                </li>
                            )
                        })}
                    </ul>
                )}
                {/* <div className="cardArea">
                    <RepaymentOfCard 
                    ref={cardRef}
                    amount={delayShouldPayAmount.split(",").join('') || ""}
                    isDelay={1} />
                </div> */}
            </div>
            {/* 跳转按钮 */}
            <div className="P-repayment_btnArea">
                <Button
                content="Confirm"
                clickFunc={() => repayConfirm()}
                btnStyle={{background: "#09C8AF", border: "2px solid #09C8AF"}} />
            </div>
        </div>
    )
}

export default RepaymentOfDelay;